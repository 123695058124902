// i18n
import i18next from 'i18next';
// icon
import { Icon } from '@iconify/react';
import homeIcon from '@iconify/icons-material-symbols/home';
import settingsIcon from '@iconify/icons-eva/settings-2-fill';
import usersIcon from '@iconify/icons-eva/people-fill';
import userAccountIcon from '@iconify/icons-mdi/account-box-multiple';

import barCode from '@iconify/icons-material-symbols/barcode';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const navConfig = () => [
  {
    title: i18next.t('sidebar.home'),
    path: '/dashboard',
    icon: getIcon(homeIcon),
  },
  {
    title: i18next.t('sidebar.products'),
    accessible: false,
    path: '/products',
    icon: getIcon(barCode),
    children: [], // Ícones da sidebar são renderizados de maneira dinâmica de acordo com o Perfil de produto do usuário, na função sideBarPermission() de DashboardSidebar.js
  },
];

// const surveyKeyboard = () => [

// ]

const surveyKeyboard = () => ({
  title: i18next.t('features.survey-keyboard.sidebar'),
  path: '/products/ratings-system',
  childrensub: [
    { title: i18next.t('features.survey-keyboard.reports.title'), path: '/products/survey-keyboard/reports' },
  ],
});

const adminNav = () => [
  {
    title: i18next.t('sidebar.user'),
    path: '/users',
    icon: getIcon(usersIcon),
  },
  {
    title: i18next.t('sidebar.manageacess'),
    path: '/manage-access',
    icon: getIcon(userAccountIcon),
  },
  {
    title: i18next.t('sidebar.settings'),
    path: '/config',
    icon: getIcon(settingsIcon),
  },
];

export { navConfig, adminNav, surveyKeyboard };
